<template>
  <div>
    <ul class="masonry">
      <li class="item">01<br /><img src="~img/img.jpg" /></li>
      <li class="item w2 h2">02<br /><img src="~img/img.jpg" /></li>
      <li class="item h3">03<br /><img src="~img/img.jpg" /></li>
      <li class="item h2">04<br /><img src="~img/img.jpg" /></li>
      <li class="item w3 h4">05<br /><img src="~img/img.jpg" /></li>
      <li class="item h4">06<br /><img src="~img/img.jpg" /></li>
      <li class="item">07<br /><img src="~img/img.jpg" /></li>
      <li class="item h2">08<br /><img src="~img/img.jpg" /></li>
      <li class="item w2 h3">09<br /><img src="~img/img.jpg" /></li>
      <li class="item h4">10<br /><img src="~img/img.jpg" /></li>
      <li class="item h2">11<br /><img src="~img/img.jpg" /></li>
      <li class="item h4">12<br /><img src="~img/img.jpg" /></li>
      <li class="item w2">13<br /><img src="~img/img.jpg" /></li>
      <li class="item">14<br /><img src="~img/img.jpg" /></li>
      <li class="item h2">15<br /><img src="~img/img.jpg" /></li>
      <li class="item">16<br /><img src="~img/img.jpg" /></li>
      <li class="item h3">17<br /><img src="~img/img.jpg" /></li>
      <li class="item">01<br /><img src="~img/img.jpg" /></li>
      <li class="item w2 h2">02<br /><img src="~img/img.jpg" /></li>
      <li class="item h3">03<br /><img src="~img/img.jpg" /></li>
      <li class="item h2">04<br /><img src="~img/img.jpg" /></li>
      <li class="item w3 h4">05<br /><img src="~img/img.jpg" /></li>
      <li class="item h4">06<br /><img src="~img/img.jpg" /></li>
      <li class="item">07<br /><img src="~img/img.jpg" /></li>
      <li class="item h2">08<br /><img src="~img/img.jpg" /></li>
      <li class="item w2 h3">09<br /><img src="~img/img.jpg" /></li>
      <li class="item h4">10<br /><img src="~img/img.jpg" /></li>
      <li class="item h2">11<br /><img src="~img/img.jpg" /></li>
      <li class="item h4">12<br /><img src="~img/img.jpg" /></li>
      <li class="item w2">13<br /><img src="~img/img.jpg" /></li>
      <li class="item">14<br /><img src="~img/img.jpg" /></li>
      <li class="item h2">15<br /><img src="~img/img.jpg" /></li>
      <li class="item">16<br /><img src="~img/img.jpg" /></li>
      <li class="item h3">17<br /><img src="~img/img.jpg" /></li>
      <li class="item">01<br /><img src="~img/img.jpg" /></li>
      <li class="item w2 h2">02<br /><img src="~img/img.jpg" /></li>
      <li class="item h3">03<br /><img src="~img/img.jpg" /></li>
      <li class="item h2">04<br /><img src="~img/img.jpg" /></li>
      <li class="item w3 h4">05<br /><img src="~img/img.jpg" /></li>
      <li class="item h4">06<br /><img src="~img/img.jpg" /></li>
      <li class="item">07<br /><img src="~img/img.jpg" /></li>
      <li class="item h2">08<br /><img src="~img/img.jpg" /></li>
      <li class="item w2 h3">09<br /><img src="~img/img.jpg" /></li>
      <li class="item h4">10<br /><img src="~img/img.jpg" /></li>
      <li class="item h2">11<br /><img src="~img/img.jpg" /></li>
      <li class="item h4">12<br /><img src="~img/img.jpg" /></li>
      <li class="item w2">13<br /><img src="~img/img.jpg" /></li>
      <li class="item">14<br /><img src="~img/img.jpg" /></li>
      <li class="item h2">15<br /><img src="~img/img.jpg" /></li>
      <li class="item">16<br /><img src="~img/img.jpg" /></li>
      <li class="item h3">17<br /><img src="~img/img.jpg" /></li>
    </ul>
  </div>
</template>


<script>
import Masonry from "masonry-layout";
import imagesloaded from "imagesloaded"; // 引入imagesloaded库
export default {
  data() {
    return {
      masonry: null,
    };
  },
  created() {
    // this.msnry();
  },
  mounted() {
    this.msnry();
  },
  methods: {
    msnry() {
      this.masonry = document.querySelector(".masonry");
      let msy = new Masonry(this.masonry, {
        // options...
        itemSelector: ".item",
        columnWidth: 20,
        isAnimated: true,
      });
      imagesloaded(".item", () => {
        msy.layout(); // 图片加载完成后重新绘制。
      });
    },
  },
  // updated() {
  //   this.msnry();
  // },
};
</script>

<style lang="less" scoped>
.masonry {
  margin: auto;
}
.item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 40px;
  text-align: center;
  line-height: 60px;
  margin-bottom: 20px;
  color: #fff;
  float: left;
  background-color: #9432ba;
  border: 2px solid #d29be7;
  border-radius: 5px;
  overflow: hidden;
}

.item {
  width: 200px;
}

.item.w2 {
  width: 400px;
}

.item.w3 {
  width: 600px;
}

.item.w4 {
  width: 800px;
}

.item {
  min-height: 60px;
}

.item.h2 {
  min-height: 120px;
}

.item.h3 {
  min-height: 180px;
}

.item.h4 {
  min-height: 240px;
}

img {
  max-width: 100%;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@media screen and (max-width: 900px) {
  .item {
    width: 100% !important;
  }
}
</style>